import { MrDialog } from '../mr-dialog/mr-dialog';

declare global {
	interface Element {
		inert: boolean
	}
}

export class MrModalDialog extends MrDialog {
	/**
	 * Called when an unexpected error occurs.
	 * Make sure to do all needed cleanup.
	 */
	override gracefullShutdown(): void {
		super.gracefullShutdown();

		Array.from( document.body.children ).forEach( ( child ) => {
			if ( child === this ) {
				return;
			}

			try {
				child.inert = false;
			} catch ( err ) {
				console.warn( err );
			}
		} );
	}

	/**
	 * Called before transitioning to the "open" state
	 * Updates focusability
	 */
	override async willOpen(): Promise<void> {
		await super.willOpen();

		// Update focusability
		// https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal
		// Windows under a modal dialog are inert.
		// That is, users cannot interact with content outside an active dialog window.
		// Inert content outside an active dialog is typically visually obscured or dimmed
		// so it is difficult to discern, and in some implementations,
		// attempts to interact with the inert content cause the dialog to close.
		Array.from( document.body.children ).forEach( ( child ) => {
			if ( child === this ) {
				return;
			}

			try {
				child.inert = true;
			} catch ( err ) {
				console.warn( err );
			}
		} );
	}

	/**
	 * Called before transitioning to the "closed" state
	 * Updates focusability
	 */
	override async willClose(): Promise<void> {
		await super.willClose();

		// Update focusability
		// https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal
		// Windows under a modal dialog are inert.
		// That is, users cannot interact with content outside an active dialog window.
		// Inert content outside an active dialog is typically visually obscured or dimmed
		// so it is difficult to discern, and in some implementations,
		// attempts to interact with the inert content cause the dialog to close.
		Array.from( document.body.children ).forEach( ( child ) => {
			if ( child === this ) {
				return;
			}

			try {
				child.inert = false;
			} catch ( err ) {
				console.warn( err );
			}
		} );
	}
}

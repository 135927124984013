// https://stackoverflow.com/a/51819649/4263818
export const distance = ( lo1, la1, lo2, la2 ) => {
	const radla1 = Math.PI * la1 / 180;
	const radla2 = Math.PI * la2 / 180;
	const theta = lo1 - lo2;
	const radtheta = Math.PI * theta / 180;
	let dist = Math.sin( radla1 ) * Math.sin( radla2 ) + Math.cos( radla1 ) * Math.cos( radla2 ) * Math.cos( radtheta );

	if ( 1 < dist ) {
		dist = 1;
	}

	dist = Math.acos( dist );
	dist = dist * 180 / Math.PI;
	dist = dist * 60 * 1.1515;

	// There normally is a conversion to kilometers here, but we don't care about the actual value

	return dist;
};

// Expects : [{ la: ..., lo: ... }, { la: ..., lo: ... }]
export const sortedElementsWithLoLaByDistanceTo = ( lo, la, els ) => {
	const elements = els;

	elements.forEach( ( element ) => {
		if ( !element.lo || !element.la ) {
			// Will be sorted last
			element.distance = Number.MAX_SAFE_INTEGER;

			return;
		}

		element.distance = distance( lo, la, element.lo, element.la );
	} );

	elements.sort( ( a, b ) => {
		return a.distance - b.distance;
	} );

	return elements;
};

// Expects : [{ points: [...] }, { points: [...] }]
//
// Each element can have multiple points
// - loop all elements
// - loop all points in an element
// - set a distance on each point
// - sort all points
// - sort all elements by the distance on the first point
// -> return sorted elements
export const sortedElementsWithMultiplePointsByDistanceTo = ( lo, la, els ) => {
	if ( !els || !els.length ) {
		return els;
	}

	const elements = els.filter( ( element ) => {
		return ( element.points && element.points.length );
	} );

	elements.forEach( ( element ) => {
		element.points = sortedElementsWithLoLaByDistanceTo( lo, la, element.points );
	} );

	elements.sort( ( a, b ) => {
		const aDist = a.points[0].distance;
		const bDist = b.points[0].distance;

		return aDist - bDist;
	} );

	return elements;
};

export const fetchAllEstablishments = async() => {
	let lang = 'nl';
	if ( document.documentElement && document.documentElement.getAttribute ) {
		lang = document.documentElement.getAttribute( 'lang' ) || 'nl';
	}

	const resp = await fetch( `/wp-json/establishments/all.json?lang=${lang}` );
	if ( !resp.ok ) {
		return;
	}

	const data = await resp.json();

	return data;
};

export const fetchAllEstablishmentsAsGeoJSON = async() => {
	const data = await fetchAllEstablishments();

	const out = {
		type: 'FeatureCollection',
		features: [],
	};

	data.forEach( ( establishment ) => {
		if (
			!establishment ||
			!establishment.points ||
			!establishment.points.length
		) {
			return;
		}

		establishment.points.forEach( ( point ) => {
			if ( !point || !point.la || !point.lo ) {
				return;
			}

			// NOTE : hidden locations are used to mess with the location lookup, do not display them here
			if ( true === point.hidden ) {
				return;
			}

			out.features.push( {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [
						point.lo,
						point.la,
					],
				},
				properties: {
					title: establishment.title,
					id: establishment.id,
					lo: point.lo,
					la: point.la,
				},
			} );
		} );
	} );

	return out;
};

export const fetchEstablishmentPartial = async( id, variant = 'establishments' ) => {
	if ( !id ) {
		return Promise.resolve();
	}

	let lang = 'nl';
	if ( document.documentElement && document.documentElement.getAttribute ) {
		lang = document.documentElement.getAttribute( 'lang' ) || 'nl';
	}

	const resp = await fetch( `/wp-json/establishments/banner.json?id=${id}&variant=${variant}&lang=${lang}` );
	if ( !resp.ok ) {
		return;
	}

	const data = await resp.json();

	return data;
};

export const fetchEstablishmentPartials = async( ids, variant = 'establishments' ) => {
	if ( !ids || !ids.length ) {
		return Promise.resolve( [] );
	}

	const promises = [];
	ids.forEach( ( id ) => {
		promises.push( fetchEstablishmentPartial( id, variant ) );
	} );

	return Promise.all( promises ).then( ( responses ) => {
		return responses.filter( ( response ) => {
			return !!response;
		} );
	} );
};

/* core-web-ignore @mrhenry/core-web/modules/WebAnimations */

import { prefersReducedMotion } from '@mrhenry/wp--prefers-reduced-motion';

function getMaxTiming( timing: EffectTiming ): number {
	let max = 0;

	if ( ( timing.delay ?? 0 ) > max ) {
		max = timing.delay ?? max;
	}

	if ( ( timing.endDelay ?? 0 ) > max ) {
		max = timing.endDelay ?? max;
	}

	if ( 'auto' === timing.duration ) {
		// noop
	} else if ( 'number' === typeof timing.duration && timing.duration > max ) {
		max = timing.duration;
	}

	return max;
}

function scaleToMaxTiming( timing: EffectTiming, max: number ): OptionalEffectTiming {
	if ( timing.delay ) {
		timing.delay = timing.delay / max;
	} else {
		timing.delay = 0;
	}

	if ( timing.endDelay ) {
		timing.endDelay = timing.endDelay / max;
	} else {
		timing.endDelay = 0;
	}

	if ( 'auto' === timing.duration ) {
		timing.duration = 1;
	} else if ( 'number' === typeof timing.duration ) {
		timing.duration = timing.duration / max;
	} else if ( 'undefined' === typeof timing.duration ) {
		// see: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1567
		timing.duration = undefined;
	} else {
		// see: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1567
		timing.duration = 1;
	}

	if ( timing.iterations === Infinity ) {
		timing.iterations = 1.0;
	}

	// see: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1567
	return timing as OptionalEffectTiming;
}

// Keep the same timing scale, just make it all very small
function reduceMotion( keyframeEffects: KeyframeEffect[] ): void {
	if ( !( 'KeyframeEffect' in window ) || ( 'undefined' === typeof KeyframeEffect ) ) {
		return;
	}

	if ( !KeyframeEffect.prototype.getTiming ) {
		return;
	}

	if ( !KeyframeEffect.prototype.updateTiming ) {
		return;
	}

	let maxTiming = 0;
	keyframeEffects.forEach( ( keyframeEffect ) => {
		const newMax = getMaxTiming( keyframeEffect.getTiming() );
		if ( newMax > maxTiming ) {
			maxTiming = newMax;
		}
	} );

	keyframeEffects.forEach( ( keyframeEffect ) => {
		keyframeEffect.updateTiming(
			scaleToMaxTiming(
				keyframeEffect.getTiming(),
				maxTiming
			)
		);
	} );
}

/**
 * Plays all animations and resolves when all animations are done.
 *   Is aware of "@mrhenry/wp--prefers-reduced-motion"
 * @param  {KeyframeEffect[]} keyframeEffects Collection of KeyFrameEffects to play and wait for.
 */
export function playAllAnimations( keyframeEffects: KeyframeEffect[] ): Promise<void[] | void> {
	if ( !( 'Animation' in self ) ) {
		return Promise.resolve();
	}

	if ( !( 'play' in Animation.prototype ) ) {
		return Promise.resolve();
	}

	const promises: Promise<void>[] = [];

	if ( prefersReducedMotion() ) {
		// Very short animation without any delays
		reduceMotion( keyframeEffects );
	}

	keyframeEffects.forEach( ( keyframeEffect ) => {
		promises.push( new Promise( ( resolve ) => {
			const animation = new Animation( keyframeEffect, document.timeline );
			animation.onfinish = ( (): void => {
				resolve();
			} );

			animation.play();
		} ) );
	} );

	return Promise.all( promises );
}

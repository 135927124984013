import Bugsnag, { Client } from '@bugsnag/js';

declare global {
	interface Window {
		bugsnagClient: Client
	}
}

const metaValue = ( name: string ): string | null => {
	if ( !document || !document.head ) {
		return null;
	}

	const metaTag: HTMLMetaElement | null = document.head.querySelector( 'meta[name="' + name + '"][content]' );
	if ( !metaTag || !metaTag.content ) {
		return null;
	}

	return metaTag.content;
};

export const bugsnagClient = Bugsnag.createClient( {
	apiKey: metaValue( 'mrh:bugsnag' ) || '',
	appType: metaValue( 'mrh:theme' ) || 'unknown theme',
	autoTrackSessions: false,
	collectUserIp: false,
	enabledReleaseStages: [
		'production',
	],
	generateAnonymousId: true,
	releaseStage: metaValue( 'mrh:stage' ) || 'production',
	onError: ( event ) => {
		// Update Context
		const theme = metaValue( 'mrh:theme' );
		if ( theme ) {
			event.context = event.context + ' - ' + theme;
		}

		// Update Meta Data
		const id = metaValue( 'mrh:id' );
		if ( id && theme ) {
			event.addMetadata( 'site', {
				id: id,
				theme: theme,
			} );
		}

		const target = metaValue( 'mrh:browser-target' );
		if ( target ) {
			event.addMetadata( 'js-bundle', {
				target: metaValue( 'mrh:browser-target' ),
			} );
		}
	},
} );

window.bugsnagClient = bugsnagClient;

import toTitleCase from './title-case';

export const fetchMapboxLocation = async( query ) => {
	if ( !query ) {
		return;
	}

	let lang = 'nl';
	if ( document.documentElement && document.documentElement.getAttribute ) {
		lang = document.documentElement.getAttribute( 'lang' ) || 'nl';
	}

	const titleCasedQuery = toTitleCase( query );

	// Debug log : we keep this for now to quickly check what this functionality does and why
	console.log( `geo : query – "${query}"` );
	console.log( `geo : title cased – "${titleCasedQuery}"` );

	const resp = await fetch( `https://api.mapbox.com/geocoding/v5/mapbox.places/${titleCasedQuery}.json?country=be&types=region,postcode,district,place,locality,neighborhood,address&language=${lang}&autocomplete=false&access_token=pk.eyJ1IjoibXJoZW5yeS1zZXJlbmkiLCJhIjoiY2theHA5dnJwMDY1bzMycGlmYzF4NzJ3bSJ9.SenMTE8DVtIviq0Xq6kpIA` );
	if ( !resp.ok ) {
		return;
	}

	const data = await resp.json();

	return data;
};

export const fetchMapboxLocationLoLa = async( query ) => {
	const override = queryOverrides( query );
	if ( override ) {
		return override;
	}

	const data = await fetchMapboxLocation( query );
	const loLa = mapboxLoLaFromLocation( data );

	return loLa;
};

const mapboxLoLaFromLocation = ( data ) => {
	if ( !data || !data.features ) {
		return;
	}

	const result = data.features;
	if ( !result.length ) {
		return;
	}

	const firstResult = result[0];
	if ( !firstResult.center ) {
		return;
	}

	if ( firstResult.place_name ) {
		// Debug log : we keep this for now to quickly check what this functionality does and why
		console.log( `geo : place name – "${firstResult.place_name}"` );
	}

	if ( !firstResult.center.length || 2 !== firstResult.center.length ) {
		return;
	}

	// Debug log : we keep this for now to quickly check what this functionality does and why
	console.log( `geo : lo – "${firstResult.center[0]}"` );
	console.log( `geo : la – "${firstResult.center[1]}"` );

	return {
		lo: firstResult.center[0],
		la: firstResult.center[1],
	};
};

const queryOverrides = ( query ) => {
	const overrides = {
		/* eslint-disable quote-props, object-property-newline, object-curly-newline */
		'1043': { lo: 4.4028936, la: 50.853531 },
		'1044': { lo: 4.4034678, la: 50.8522062 },
		'1804': { lo: 4.4291786, la: 50.9480716 },
		'1818': { lo: 4.3981412, la: 50.9136056 },
		'1931': { lo: 4.4350213, la: 50.9125785 },
		'1935': { lo: 4.4607645, la: 50.8918303 },
		'abtsdal': { lo: 4.3678486, la: 50.9243814 },
		'bavikhove': { lo: 3.3113238, la: 50.8753958 },
		'beekkant': { lo: 4.3666685, la: 50.9333328 },
		'beigem': { lo: 4.366065, la: 50.9528359 },
		'berchem-bos': { lo: 4.4418293, la: 51.1878805 },
		'berchem': { lo: 4.4188392, la: 51.1955771 },
		'bever': { lo: 4.3344069, la: 50.9137861 },
		'bissegem': { lo: 3.2280976, la: 50.8241086 },
		'blekte': { lo: 4.0284607, la: 50.9569602 },
		'boechout': { lo: 4.3285409, la: 50.9222206 },
		'boekt': { lo: 4.4969702, la: 50.9552301 },
		'borcht': { lo: 4.4040531, la: 50.9281414 },
		'borgerhout': { lo: 4.4368144, la: 51.2225341 },
		'brabantwijk': { lo: 4.3658638, la: 50.8643695 },
		'brand': { lo: 4.4056088, la: 51.1852007 },
		'brem': { lo: 4.3790174, la: 51.1750585 },
		'catersvelden': { lo: 4.4511795, la: 51.2534296 },
		'collignon': { lo: 4.3760079, la: 50.8692042 },
		'dailly': { lo: 4.3833143, la: 50.8558975 },
		'den dam': { lo: 4.4270316, la: 51.2333207 },
		'deurne': { lo: 4.4594353, la: 51.2218805 },
		'deurnebrug': { lo: 4.4635499, la: 51.2448791 },
		'deuzeld': { lo: 4.4442487, la: 51.2314044 },
		'diamant': { lo: 4.4176412, la: 51.2144212 },
		'diegem': { lo: 4.4362631, la: 50.8940396 },
		'dieleghem': { lo: 4.3188876, la: 50.885487 },
		'doel': { lo: 4.2649752, la: 51.3105969 },
		'driekoningen': { lo: 4.467423, la: 51.2081924 },
		'duivelshoek': { lo: 4.3889093, la: 51.1659198 },
		'eilandje': { lo: 4.4096583, la: 51.235424 },
		'ekeren': { lo: 4.4181413, la: 51.2773404 },
		'elewijt': { lo: 4.4964552, la: 50.9618495 },
		'elsdonk': { lo: 4.4271094, la: 51.1638712 },
		'eppegem': { lo: 4.4544303, la: 50.9621232 },
		'ertbrugge': { lo: 4.481113, la: 51.2292983 },
		'essegem': { lo: 4.3358606, la: 50.8783609 },
		'esseghem': { lo: 4.3358606, la: 50.8783609 },
		'far west': { lo: 4.4341904, la: 50.9371209 },
		'faubourg': { lo: 4.4399196, la: 50.9290999 },
		'grimbergen': { lo: 4.3732552, la: 50.9335899 },
		'groen kwartier': { lo: 4.4195884, la: 51.2021796 },
		'groenenhoek': { lo: 4.447757, la: 51.196719 },
		'groot molenveld': { lo: 4.3958938, la: 50.9242055 },
		'gullegem': { lo: 3.1989741, la: 50.8447684 },
		'haeren': { lo: 4.4176727, la: 50.8922905 },
		'hamme': { lo: 4.2806125, la: 50.9204181 },
		'harelbeke': { lo: 3.3106613, la: 50.8566764 },
		'haren-sud': { lo: 4.4212809, la: 50.8815002 },
		'haren': { lo: 4.4176727, la: 50.8922905 },
		'haringrode': { lo: 4.4224906, la: 51.2046551 },
		'harmonie': { lo: 4.406746, la: 51.20525 },
		'helmet': { lo: 4.3892688, la: 50.8739199 },
		'hertogvelden': { lo: 4.3412089, la: 51.1756 },
		'het voor': { lo: 4.373551, la: 50.9136322 },
		'heule': { lo: 3.2347226, la: 50.8431967 },
		'heusden': { lo: 3.8036942, la: 51.0327989 },
		'hoboken': { lo: 4.3610792, la: 51.1787963 },
		'hofstade': { lo: 4.0317903, la: 50.9608451 },
		'houtem': { lo: 4.46048, la: 50.94859 },
		'humbeek': { lo: 4.3795109, la: 50.9672315 },
		'ijzerbrug': { lo: 4.4509488, la: 51.2419205 },
		'josaphat': { lo: 4.3772981, la: 50.8674164 },
		'kassei': { lo: 4.4059467, la: 50.9202423 },
		'kiel': { lo: 4.3836308, la: 51.1890842 },
		'kievitwijk': { lo: 4.4246176, la: 51.2130938 },
		'klaverken': { lo: 3.7972704, la: 51.0374795 },
		'klein molenveld': { lo: 4.3990213, la: 50.9288683 },
		'kluizevelden': { lo: 4.4424596, la: 51.2431899 },
		'koningslo': { lo: 4.3794465, la: 50.9062364 },
		'koornbloem': { lo: 4.3821341, la: 51.1694921 },
		'kortenhoek': { lo: 4.0250784, la: 50.9635895 },
		'kortrijk': { lo: 3.2577467, la: 50.8194658 },
		'kriekenhof': { lo: 4.4564527, la: 51.2040232 },
		'kruisbaanvelden': { lo: 4.4436586, la: 51.2516703 },
		'kuurne': { lo: 3.2869989, la: 50.8539706 },
		'kwadevelden': { lo: 4.4393697, la: 51.2570908 },
		'laeken': { lo: 4.3480731, la: 50.877839 },
		'lakbors': { lo: 4.4509006, la: 51.2296795 },
		'laken': { lo: 4.3480731, la: 50.877839 },
		'lambrechtshoeken': { lo: 4.4318998, la: 51.2537604 },
		'leeuwerik': { lo: 4.5686013, la: 51.2970308 },
		'lillo': { lo: 4.28026, la: 51.30436 },
		'linkeroever': { lo: 4.391813, la: 51.2302269 },
		'luchtbal': { lo: 4.4244432, la: 51.2570203 },
		'luithagen': { lo: 4.4366205, la: 51.1743118 },
		'machelen': { lo: 4.4290137, la: 50.9140229 },
		'marke': { lo: 3.2335928, la: 50.8070105 },
		'markgrave': { lo: 4.402366, la: 51.1975527 },
		'melsbroek': { lo: 4.4787204, la: 50.9153487 },
		'merksem': { lo: 4.4485629, la: 51.2512626 },
		'merksembrug': { lo: 4.4649822, la: 51.2395293 },
		'merksemheide': { lo: 4.4662482, la: 51.2499999 },
		'middelheim': { lo: 4.4079745, la: 51.182931 },
		'mierendonk': { lo: 4.3626881, la: 50.9312993 },
		'molenbeek': { lo: 4.3303, la: 50.8522 },
		'molenveld': { lo: 4.3939009, la: 51.1741101 },
		'moretusbrug': { lo: 4.339031, la: 51.1717524 },
		'morkhoven': { lo: 4.4583839, la: 51.2111582 },
		'mutsaard': { lo: 4.3600544, la: 50.8993582 },
		'nachtegalenhof': { lo: 4.3515462, la: 51.1608115 },
		'neder-over-heembeek': { lo: 4.37188, la: 50.89622 },
		'neerland': { lo: 4.3934798, la: 51.1612102 },
		'northern quarter': { lo: 4.3574792, la: 50.8607737 },
		'ossel': { lo: 4.2787671, la: 50.9223795 },
		'oud-berchem': { lo: 4.4212514, la: 51.1968417 },
		'oude barreel': { lo: 4.458459, la: 51.2509199 },
		'oude molen': { lo: 4.3129706, la: 50.9203505 },
		'overbos': { lo: 4.3198505, la: 50.9065899 },
		'oversnes': { lo: 4.3993002, la: 51.1615298 },
		'perk': { lo: 4.4959188, la: 50.9348795 },
		'peutie': { lo: 4.4554442, la: 50.9273603 },
		'plasky': { lo: 4.3949443, la: 50.8502452 },
		'polder': { lo: 4.3089, la: 51.3592 },
		'polderstad': { lo: 4.341056, la: 51.1847754 },
		'populierendal': { lo: 4.3879169, la: 50.9202693 },
		'quartier brabant': { lo: 4.3658638, la: 50.8643695 },
		'quartier nord': { lo: 4.3574792, la: 50.8607737 },
		'ransbeek': { lo: 4.4074327, la: 50.9097493 },
		'relegem': { lo: 4.277029, la: 50.8990813 },
		'rodenburg': { lo: 3.2506764, la: 50.8043532 },
		'rooi': { lo: 4.4378006, la: 51.1845215 },
		'rozemaai': { lo: 4.4134784, la: 51.2679266 },
		'rubenkasteel': { lo: 4.4778621, la: 50.9595012 },
		'schaerbeek': { lo: 4.3772981, la: 50.8674164 },
		'schom': { lo: 4.4240785, la: 51.1828806 },
		'schoonbroek': { lo: 4.4084036, la: 51.2759879 },
		'schoonsel': { lo: 4.3551806, la: 51.1626702 },
		'schoonselhof': { lo: 4.3681322, la: 51.1656432 },
		'seefhoek': { lo: 4.427163, la: 51.2241382 },
		'sint-andries': { lo: 4.3985331, la: 51.2134215 },
		'sint job': { lo: 4.58336, la: 51.29277 },
		'slijkhoek': { lo: 4.4051689, la: 51.1737603 },
		'spaanse-linde': { lo: 4.3675697, la: 50.9213599 },
		'stasegem': { lo: 3.310318, la: 50.8318003 },
		'stapsteen': { lo: 3.8078704, la: 51.0395893 },
		'strombeek-bever': { lo: 4.3556392, la: 50.9103733 },
		'stuivenbergwijk': { lo: 4.3690825, la: 51.1719441 },
		'terdelt': { lo: 4.3911607, la: 50.8660288 },
		'valaar': { lo: 4.3825847, la: 51.1773857 },
		'vilvoorde': { lo: 4.4257868, la: 50.9272511 },
		'vilvorde': { lo: 4.4257868, la: 50.9272511 },
		'wemmel': { lo: 4.3009225, la: 50.91032 },
		'wevelgem': { lo: 3.1836319, la: 50.8089179 },
		'wijngaardveld': { lo: 4.0454096, la: 50.960471 },
		'wijveld': { lo: 4.0365502, la: 50.96512 },
		'wilrijk': { lo: 4.3942868, la: 51.1683102 },
		'woluwe': { lo: 4.43209, la: 50.84891 },
		'zandberg': { lo: 3.8138303, la: 51.0371708 },
		'zandhekken': { lo: 3.8092303, la: 51.0280706 },
		'zemst': { lo: 4.4291786, la: 50.9480716 },
		'zepten': { lo: 4.4390774, la: 51.1815792 },
		'zijp': { lo: 4.3282807, la: 50.9182301 },
		'zonneveld': { lo: 4.3747848, la: 50.9420291 },
		'zurenborg': { lo: 4.4292015, la: 51.2070246 },
		'zwaantjes': { lo: 4.3694204, la: 51.1835598 },
		// establishment names
		'allemeersch': { lo: 3.0358954, la: 51.133083 },
		'bloyart': { lo: 3.263325699999999, la: 50.83781519999999 },
		'brainoises': { lo: 4.136100, la: 50.610270 },
		'brosse': { lo: 3.7957818, la: 51.042674 },
		'brossé': { lo: 3.7957818, la: 51.042674 },
		'd\'haene': { lo: 3.2524617, la: 50.8142521 },
		'de hertogh': { lo: 4.3824368, la: 50.8942039 },
		'de kapel': { lo: 4.530152999999999, la: 50.99608299999999 },
		'de polder': { lo: 4.319372, la: 51.3454151 },
		'de rycke': { lo: 4.016073899999999, la: 50.90352799999999 },
		'debonne': { lo: 3.2295181, la: 50.8410001 },
		'debonné': { lo: 3.2295181, la: 50.8410001 },
		'dhaene': { lo: 3.2524617, la: 50.8142521 },
		'eilandje antwerpen': { lo: 4.4104506, la: 51.2301156 },
		'ergo & poncelet': { lo: 4.4016806, la: 50.8232595 },
		'ergo en poncelet': { lo: 4.4016806, la: 50.8232595 },
		'ergo et poncelet': { lo: 4.4016806, la: 50.8232595 },
		'ergo poncelet': { lo: 4.4016806, la: 50.8232595 },
		'feyaerts': { lo: 4.651395900000001, la: 50.973358 },
		'forrier': { lo: 4.317724599999999, la: 50.8575588 },
		'gooris & van camp': { lo: 4.554123999999999, la: 50.9948993 },
		'gooris en van camp': { lo: 4.554123999999999, la: 50.9948993 },
		'gooris et van camp': { lo: 4.554123999999999, la: 50.9948993 },
		'gooris van camp': { lo: 4.554123999999999, la: 50.9948993 },
		'groffen': { lo: 4.319372, la: 51.3454151 },
		'haast': { lo: 4.4256347, la: 51.3192287 },
		'haene': { lo: 3.2524617, la: 50.8142521 },
		'leclercq': { lo: 4.072470, la: 50.574490 },
		'lenchant': { lo: 4.3696888, la: 51.0868364 },
		'lits': { lo: 4.4113703, la: 50.8434249 },
		'lucas': { lo: 4.044720, la: 50.695030 },
		'mahy': { lo: 4.3741892, la: 50.8669684 },
		'melis': { lo: 4.279490, la: 51.219550 },
		'planckaert': { lo: 3.308590, la: 50.773230 },
		'pollie': { lo: 3.1308494, la: 50.7979838 },
		'popelier': { lo: 4.436081, la: 50.8092391 },
		'quirijnen': { lo: 4.489160000000001, la: 51.2946476 },
		'rigole': { lo: 3.2379437, la: 50.82609249999999 },
		'servaty': { lo: 2.907713199999999, la: 51.21845070000001 },
		'stevens & lenchant': { lo: 4.3263251, la: 51.0419966 },
		'stevens en lenchant': { lo: 4.3263251, la: 51.0419966 },
		'stevens et lenchant': { lo: 4.3263251, la: 51.0419966 },
		'stevens lenchant': { lo: 4.3263251, la: 51.0419966 },
		'van dael': { lo: 4.1448396, la: 51.1585855 },
		'vanderlinden': { lo: 4.276150, la: 50.762140 },
		'verhoeven': { lo: 3.8103065, la: 51.1985293 },
		/* eslint-enable */
	};

	const clean = query.trim().toLowerCase();

	return overrides[clean] || null;
};

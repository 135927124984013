import { sortedElementsWithMultiplePointsByDistanceTo } from './distance.js';
import { fetchEstablishmentPartials, fetchAllEstablishments } from './api-establishments.js';
import { fetchMapboxLocationLoLa } from './api-mapbox.js';

export const renderEstablishmentSearchResults = async( options ) => {
	const query = options.query;
	const liveArea = options.liveArea;
	const liveAreaLoadedMsg = options.liveAreaLoadedMsg;
	const liveAreaLoadingMsg = options.liveAreaLoadingMsg;
	const renderArea = options.renderArea;
	const renderVariant = options.renderVariant;

	let allEstablishments = options.allEstablishments;
	let lo = options.lo;
	let la = options.la;

	if ( liveArea && liveAreaLoadingMsg ) {
		liveArea.innerHTML = liveAreaLoadingMsg;
	}

	if ( !query ) {
		return false;
	}

	const locationData = await lookupLocationData( query );
	const loLa = locationData.loLa;
	if ( !loLa ) {
		return false;
	}

	allEstablishments = locationData.allEstablishments;
	lo = loLa.lo;
	la = loLa.la;

	let sortedEstablishments = sortedElementsWithMultiplePointsByDistanceTo( lo, la, allEstablishments );
	if ( 5 < sortedEstablishments.length ) {
		sortedEstablishments = sortedEstablishments.slice( 0, 5 );
	}

	const establishmentIDs = sortedEstablishments.map( ( establishment ) => {
		return establishment.id;
	} );

	const partials = await fetchEstablishmentPartials( establishmentIDs, renderVariant );

	renderArea.innerHTML = '';

	partials.forEach( ( partial ) => {
		renderArea.innerHTML += partial.html;
	} );

	let scrollToEl = false;
	if ( renderArea.hasAttribute( 'scroll-to-el-on-render' ) ) { // The render area might tell us to scroll to another element instead
		scrollToEl = document.getElementById( renderArea.getAttribute( 'scroll-to-el-on-render' ) );
	}

	if ( !scrollToEl ) {
		scrollToEl = renderArea;
	}

	scrollToEl.scrollIntoView( {
		behavior: 'smooth',
	} );

	// Search for the first focusable element in the results
	const firstFocusableElement = renderArea.querySelector( 'a' );

	if ( firstFocusableElement ) {
		/** Because we smooth scroll further down, we "have" to wait a while before setting focus.
		 * This is "needed" because settings focus stops the page from (smooth) scrolling.
		 * 560ms is a guesstimate, and the max amount of time we should wait before setting the focus.
		 * This way AT-users don't have to wait (too) long.
		 * If the scroll hasn't finished yet, it just won't land on the perfect spot. No biggie.
		 */
		setTimeout( () => {
			window.requestAnimationFrame( () => {
				firstFocusableElement.focus();
			} );
		}, 560 );
	}

	if ( liveArea && liveAreaLoadedMsg ) {
		const result_count = allEstablishments.length || 0;

		if ( 1 === result_count && liveAreaLoadedMsg.singular ) {
			liveArea.innerHTML = result_count + ' ' + liveAreaLoadedMsg.singular;
		} else if ( 1 !== result_count && liveAreaLoadedMsg.plural ) {
			liveArea.innerHTML = result_count + ' ' + liveAreaLoadedMsg.plural;
		} else {
			liveArea.innerHTML = '';
		}
	}

	return true;
};

async function lookupLocationData( query ) {
	if ( !query ) {
		return;
	}

	return Promise.all( [
		fetchAllEstablishments(),
		fetchMapboxLocationLoLa( query ),
	] ).then( ( responses ) => {
		return {
			allEstablishments: responses[0],
			loLa: responses[1],
		};
	} );
}

import { fetchAllEstablishments, fetchAllEstablishmentsAsGeoJSON } from '../../helpers/api-establishments.js';
import { renderEstablishmentSearchResults } from '../../helpers/establishments-search-results.js';

let mapBoxDependenciesLoader = null;

const loadMapBoxDependencies = () => {
	if ( mapBoxDependenciesLoader ) {
		return mapBoxDependenciesLoader;
	}

	const mapboxVersion = 'v1.7.0';

	const loadJS = () => {
		return new Promise( ( resolve, reject ) => {
			const script = document.createElement( 'script' );
			script.src = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.js`;
			script.onload = () => {
				return resolve();
			};
			script.onerror = () => {
				return reject( new Error( 'mapbox gl js failed to load' ) );
			};
			script.async = true;

			const first = document.head.getElementsByTagName( 'script' )[0];
			first.parentNode.insertBefore( script, first );
		} );
	};

	const loadCSS = () => {
		return new Promise( ( resolve, reject ) => {
			const link = document.createElement( 'link' );
			link.rel = 'stylesheet';
			link.href = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.css`;
			link.onload = () => {
				return resolve();
			};
			link.onerror = () => {
				return reject( new Error( 'mapbox gl css failed to load' ) );
			};

			const first = document.head.getElementsByTagName( 'link' )[0];
			first.parentNode.insertBefore( link, first );
		} );
	};

	mapBoxDependenciesLoader = Promise.all( [
		loadJS(),
		loadCSS(),
	] );

	return mapBoxDependenciesLoader;
};

const initializeMap = ( containerId ) => {
	return new Promise( ( resolve, reject ) => {
		if ( !window.mapboxgl ) {
			reject( new Error( 'mapboxgl not found' ) );

			return;
		}

		window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeS1zZXJlbmkiLCJhIjoiY2theHA5dnJwMDY1bzMycGlmYzF4NzJ3bSJ9.SenMTE8DVtIviq0Xq6kpIA';
		const map = new window.mapboxgl.Map( {
			container: containerId,
			style: 'mapbox://styles/mrhenry-sereni/ckaxt4ypv03z41illgvarncs6',
			center: [
				3.9619468,
				51.0879856,
			],
			zoom: 7,
		} );

		map.dragRotate.disable();
		map.touchZoomRotate.disableRotation();
		map.scrollZoom.disable();

		const nav = new window.mapboxgl.NavigationControl( {
			showCompass: false,
			showZoom: true,
		} );

		map.addControl( nav, 'top-left' );

		map.on( 'load', () => {
			resolve( map );

			return;
		} );

		map.on( 'error', ( e ) => {
			if ( e && e.error && e.error.message ) {
				reject( new Error( e.error.message ) );

				return;
			}

			reject( new Error( 'mapbox map failed to initialize' ) );

			return;
		} );
	} );
};

class MrEstablishmentsMap extends HTMLElement {
	constructor() {
		super();

	}

	connectedCallback() {
		this.render();
	}

	disconnectedCallback() {
		try {
			if ( this._map ) {
				this._map.remove();
				this._map = null;
			}

			const messageArea = document.getElementById( this.getAttribute( 'message-area' ) );
			if ( messageArea ) {
				messageArea.innerHTML = '';
			}

			const renderArea = document.getElementById( this.getAttribute( 'render-area' ) );
			renderArea.innerHTML = '';

		} catch ( err ) {
			// no fallback possible here
			console.warn( err );
		}
	}

	async render() {
		const messageArea = document.getElementById( this.getAttribute( 'message-area' ) );
		const loadingMapMsg = this.getAttribute( 'loading-map-message' );

		if ( messageArea && loadingMapMsg ) {
			messageArea.innerHTML = loadingMapMsg;
		}

		try {
			await loadMapBoxDependencies();
			const map = await initializeMap( 'establishments-mapbox-map-container' );
			this._map = map;

			const loadingEstablishmentsMsg = this.getAttribute( 'loading-establishments-message' );

			if ( messageArea && loadingEstablishmentsMsg ) {
				messageArea.innerHTML = loadingEstablishmentsMsg;
			}

			const allEstablishmentsGeoJSON = await fetchAllEstablishmentsAsGeoJSON();

			allEstablishmentsGeoJSON.features.forEach( ( marker ) => {

				// create a HTML element for each feature
				const markerButton = document.createElement( 'button' );
				markerButton.title = marker.properties.title;
				markerButton.className = 'marker';
				markerButton.addEventListener( 'click', ( e ) => {
					e.preventDefault();
					e.stopPropagation();

					this.renderResults( marker );
				} );

				// make a marker for each feature and add to the map
				new window.mapboxgl.Marker( markerButton )
					.setLngLat( marker.geometry.coordinates )
					.addTo( map );
			} );

			if ( messageArea ) {
				messageArea.innerHTML = '';
			}
		} catch ( err ) {
			console.warn( err );

			const loadingErrorsMsg = this.getAttribute( 'loading-error-message' );

			if ( messageArea && loadingErrorsMsg ) {
				messageArea.classList.add( 'is-error' );
				messageArea.innerHTML = loadingErrorsMsg;
			}

			return;
		}
	}

	async renderResults( marker ) {
		try {
			const allEstablishments = await fetchAllEstablishments();
			const renderArea = document.getElementById( this.getAttribute( 'render-area' ) );

			await renderEstablishmentSearchResults( {
				renderArea: renderArea,
				renderVariant: this.getAttribute( 'render-variant' ),
				allEstablishments: allEstablishments,
				lo: marker.properties.lo,
				la: marker.properties.la,
			} );
		} catch ( err ) {
			const messageArea = document.getElementById( this.getAttribute( 'message-area' ) );
			const loadingErrorsMsg = this.getAttribute( 'loading-error-message' );

			if ( messageArea && loadingErrorsMsg ) {
				messageArea.classList.add( 'is-error' );
				messageArea.innerHTML = loadingErrorsMsg;
			}

			console.warn( err );
		}
	}
}

customElements.define( 'mr-establishments-map', MrEstablishmentsMap );

import { playAllAnimations } from '@mrhenry/wp--play-all-animations';

declare global {
	interface Element {
		inert: boolean
	}
}

type FocusableElement = Element & Record<'focus', () => void> | null;

export class MrDialog extends HTMLElement {
	static get observedAttributes(): Array<string> {
		return [
			'disabled',
			'data-state',
		];
	}

	// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
	// Escape: Closes the dialog.
	#escapeHandler = ( e: KeyboardEvent ): void => {
		if ( 'Escape' !== e.code ) {
			return;
		}

		if ( ( document.activeElement !== this ) && ( !this.contains( document.activeElement ) ) ) {
			return;
		}

		this.updateState( 'close' );
	};

	#previousActiveElement:FocusableElement = null;

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	connectedCallback(): void {
		// Default State
		if ( !this.state ) {
			this.state = 'closed';

			try {
				this.inert = true;
			} catch ( err ) {
				console.warn( err );
			}
		}
	}

	disconnectedCallback(): void {
		// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
		// Escape: Closes the dialog.
		window.removeEventListener( 'keydown', this.#escapeHandler );
	}

	// Attributes
	override setAttribute( attr: string, value: string ): void {
		if ( this.disabled ) {
			return;
		}

		if ( 'data-state' === attr ) {
			const states = [
				'opening',
				'open',
				'closing',
				'closed',
				'broken',
			];

			if ( -1 === states.indexOf( value ) ) {
				return;
			}

			super.setAttribute( 'data-state', value );

			return;
		}

		super.setAttribute( attr, value );
	}

	override removeAttribute( attr: string ): void {
		if ( this.disabled && 'disabled' !== attr ) {
			return;
		}

		super.removeAttribute( attr );
	}

	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get state() :string {
		return this.getAttribute( 'data-state' ) || '';
	}

	set state( value: string ) {
		this.setAttribute( 'data-state', value );
	}

	/**
	 * Update the state of the dialog
	 * @param  {string} directive The directive for the state machine
	 * @return
	 */
	async updateState( directive: string ): Promise<void> {
		if ( this.disabled ) {
			return;
		}

		try {

			switch ( this.state ) {
				case 'closed':
					switch ( directive ) {
						case 'open':
							// Store current focus
							if ( document.activeElement && 'focus' in document.activeElement ) {
								this.#previousActiveElement = document.activeElement as FocusableElement;
							}

							await this.willOpen();

							// Update focusability
							try {
								this.inert = false;
							} catch ( err ) {
								console.warn( err );
							}

							// Update state
							this.state = 'opening';

							// Set focus
							window.requestAnimationFrame( () => {
								// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
								// When a dialog opens, focus placement depends on the nature and size of the content.
								// In all circumstances, focus moves to an element contained in the dialog.
								this.firstFocusableElement()?.focus();
							} );

							await playAllAnimations( this.openAnimations() );

							await this.didOpen();

							// Update state
							this.state = 'open';

							// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
							// Escape: Closes the dialog.
							window.addEventListener( 'keydown', this.#escapeHandler );

							break;
						default:
					}
					break;
				case 'open':
					switch ( directive ) {
						case 'close':
							await this.willClose();

							// Update focusability
							try {
								this.inert = true;
							} catch ( err ) {
								console.warn( err );
							}

							// Update state
							this.state = 'closing';

							// Reset current focus
							window.requestAnimationFrame( () => {
								if ( this.#previousActiveElement ) {
									this.#previousActiveElement.focus();
								}
							} );

							await playAllAnimations( this.closeAnimations() );

							await this.didClose();

							// Update state
							this.state = 'closed';

							// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
							// Escape: Closes the dialog.
							window.removeEventListener( 'keydown', this.#escapeHandler );

							break;
						default:
					}
					break;
				default:
			}

		} catch ( err ) {
			this.state = 'broken';
			this.disabled = true;

			console.warn( err );

			if ( 'bugsnagClient' in window ) {
				window.bugsnagClient.notify( err );
			}

			this.gracefullShutdown();
		}
	}

	/**
	 * Called when an unexpected error occurs.
	 * Make sure to do all needed cleanup.
	 */
	gracefullShutdown(): void {
		try {
			this.inert = true;
		} catch ( err ) {
			this.hidden = true;
			console.warn( err );
		}
	}

	/**
	 * Called before transitioning to the "open" state
	 * Optionally implement this in your sub class
	 */
	async willOpen(): Promise<void> {
		await Promise.resolve();
	}

	/**
	 * Called after transitioning to the "open" state
	 * Optionally implement this in your sub class
	 */
	async didOpen(): Promise<void> {
		await Promise.resolve();
	}

	/**
	 * Called before transitioning to the "closed" State
	 * Optionally implement this in your sub class
	 */
	async willClose(): Promise<void> {
		await Promise.resolve();
	}

	/**
	 * Called after transitioning to the "closed" state
	 * Optionally implement this in your sub class
	 */
	async didClose(): Promise<void> {
		await Promise.resolve();
	}

	/**
	 * The animations to play and wait for when opening the dialog
	 * Optionally implement this in your sub class
	 * @return {Array<KeyframeEffect>} The KeyframeEffects to play
	 */
	openAnimations(): Array<KeyframeEffect> {
		return [];
	}

	/**
	 * The animations to play and wait for when closing the dialog
	 * Implement this in your sub class
	 * @return {Array<KeyframeEffect>} The KeyframeEffects to play
	 */
	closeAnimations(): Array<KeyframeEffect> {
		return [];
	}

	// https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
	// When a dialog opens, focus placement depends on the nature and size of the content.
	// - In all circumstances, focus moves to an element contained in the dialog.
	// - Unless a condition where doing otherwise is advisable,
	//   focus is initially set on the first focusable element.
	// - If content is large enough that focusing the first interactive element could cause the beginning of content to scroll out of view,
	//   it is advisable to add tabindex=-1 to a static element at the top of the dialog,
	//   such as the dialog title or first paragraph, and initially focus that element.
	// - If a dialog contains the final step in a process that is not easily reversible,
	//   such as deleting data or completing a financial transaction,
	//   it may be advisable to set focus on the least destructive action,
	//   especially if undoing the action is difficult or impossible.
	//   The Alert Dialog Pattern is often employed in such circumstances.
	// - If a dialog is limited to interactions that either provide additional information or continue processing,
	//   it may be advisable to set focus to the element that is likely to be most frequently used,
	//   such as an OK or Continue button.
	firstFocusableElement(): HTMLElement | void {
		if ( !this.parentNode ) {
			return this;
		}

		const autoFocusChild = this.querySelector( '[modal-autofocus]' );
		if ( autoFocusChild && autoFocusChild instanceof HTMLElement ) {
			return autoFocusChild;
		}

		const firstFocusableChild = this.querySelector( 'button, [href], input, radio, select, textarea, [tabindex]' );
		if ( firstFocusableChild && firstFocusableChild instanceof HTMLElement ) {
			return firstFocusableChild;
		}

		return this;
	}
}

class MrCarousel extends HTMLElement {
	constructor() {
		/** If you define a constructor, always call super() first!
		 * This is specific to CE and required by the spec.
		 */
		super();

		this.currentIndex = 0;
		this.delay = 5000;
		this.animateDelay = 320; // The transition delay from carousel.css
		this.timeout = null;
		this.hasManuallyRestarted = false;
		this.hasManuallyPaused = false;
		this.animating = false;
		this.direction = 'next';

		this._clickHandler = ( e ) => {
			// ignore clicks with modifier keys : shift, ctrl, alt,...
			if ( e && e.metaKey ) {
				return;
			}

			// Check if target exist
			if ( !e.target ) {
				return;
			}

			if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
				this.goToNextItem();

				e.preventDefault();
				e.stopPropagation();

				return;
			}

			if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
				this.goToPreviousItem();

				e.preventDefault();
				e.stopPropagation();

				return;
			}

			if ( e.target.hasAttribute( 'data-carousel-play-pause' ) ) {
				if ( this.isPlaying() ) {
					this.pause();
					e.target.setAttribute( 'aria-label', e.target.dataset.play );
					this.hasManuallyPaused = true;
				} else {
					this.play();
					e.target.setAttribute( 'aria-label', e.target.dataset.stop );
					this.hasManuallyRestarted = true;
					this.hasManuallyPaused = false;

					// Immediately show next item if user clicks play
					this.goToNextItem();
				}

				e.preventDefault();
				e.stopPropagation();

				return;
			}

			if ( e.target.hasAttribute( 'data-carousel-goto' ) ) {
				this.setToItem( parseInt( e.target.getAttribute( 'data-carousel-goto' ), 10 ) );

				e.preventDefault();
				e.stopPropagation();

				return;
			}
		};

		this._focusHandler = () => {
			if ( this.hasManuallyRestarted || !this.isPlaying() ) {
				return;
			}

			// Stop playing
			this.pause();
		};

		this._mouseEnterHandler = () => {
			if ( !this.isPlaying() ) {
				return;
			}

			// Stop playing
			this.pause();
		};

		this._mouseLeaveHandler = () => {
			if ( this.hasManuallyPaused || this.isPlaying() ) {
				return;
			}

			// Stop playing
			this.play();
		};

		this._timeoutElapsed = () => {
			if ( !this.isPlaying() ) {
				return;
			}

			this.goToNextItem();
		};

		this._timeoutReset = () => {
			if ( !this.isPlaying() ) {
				return;
			}

			clearTimeout( this.timeout );
			this.timeout = setTimeout( this._timeoutElapsed, this.delay );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();

		if ( !this.isPlaying() ) {
			this.play();
		}
	}

	disconnectedCallback() {
		this._removeEventListeners();

		if ( this.isPlaying() ) {
			this.pause();
		}

		// Reset States
		this.currentIndex = 0;
		this.delay = 5000;
		this.timeout = null;
		this.hasManuallyRestarted = false;
		this.hasManuallyPaused = false;
		this.animating = false;
		this.direction = 'next';

		// Remove animation attributes
		this.removeAttribute( 'data-carousel-animate-to-previous' );
		this.removeAttribute( 'data-carousel-animate-to-next' );
	}

	setToItem( index ) {
		// check if items not available
		if ( 2 > this.getItems().length ) {
			return;
		}

		if ( index === this.currentIndex ) {
			return;
		}

		const items = this.getItems();
		if ( index < this.currentIndex ) {
			// switch previous slide and set direction
			this.direction = 'previous';
			items[this.getPreviousIndex()].removeAttribute( 'data-carousel-item-previous' );
			items[index].removeAttribute( 'data-carousel-item-next' );
			items[index].setAttribute( 'data-carousel-item-previous', '' );
		} else {
			// switch next slide and set direction
			this.direction = 'next';
			items[this.getNextIndex()].removeAttribute( 'data-carousel-item-next' );
			items[index].removeAttribute( 'data-carousel-item-previous' );
			items[index].setAttribute( 'data-carousel-item-next', '' );
		}

		this.goToItem( index );
	}

	goToPreviousItem() {
		this.direction = 'previous';
		this.goToItem( this.getPreviousIndex() );
	}

	goToNextItem() {
		this.direction = 'next';
		this.goToItem( this.getNextIndex() );
	}

	goToItem( index ) {
		if ( this.animating ) {
			return;
		}

		// Update current index
		this.setCurrentIndex( index );

		// reset Timeout
		this._timeoutReset();

		// Animate
		this.animate();
	}

	animate() {

		// Fallback in case the transition events don't work
		const delayPromise = new Promise( ( resolve ) => {
			setTimeout(
				() => {
					resolve();
				},
				this.animateDelay + 50 // pad with 50ms, this should leave enough time for the animations to run
			);
		} );

		// Wait for transition to finish
		const transitionDonePromise = new Promise( ( resolve ) => {
			const carouselTransitioned = ( e ) => {
				if ( !e.target.hasAttribute( 'data-carousel-item' ) ) {
					return;
				}

				this.removeEventListener( 'webkitTransitionEnd', carouselTransitioned );
				this.removeEventListener( 'transitionend', carouselTransitioned );

				resolve();
			};

			this.addEventListener( 'webkitTransitionEnd', carouselTransitioned );
			this.addEventListener( 'transitionend', carouselTransitioned );
		} );

		// Start the race
		Promise.race( [
			delayPromise,
			transitionDonePromise,
		] ).then( () => {
			// Remove animation attributes
			this.removeAttribute( 'data-carousel-animate-to' );
			// Render to current index
			this.render();
			// Let the animation start again
			this.animating = false;
		} );

		// Start the animation
		window.requestAnimationFrame( () => {
			this.animating = true;
			this.setAttribute( 'data-carousel-animate-to', this.direction );
		} );
	}

	render() {
		// check if items not available
		if ( 2 > this.getItems().length ) {
			return;
		}

		// get current, previous and next items
		const items = this.getItems();
		const previous = items[this.getPreviousIndex()];
		const current = items[this.currentIndex];
		const next = items[this.getNextIndex()];

		// set attributes
		// first reset all items
		items.forEach( ( item ) => {
			item.removeAttribute( 'data-carousel-item-previous' );
			item.removeAttribute( 'data-carousel-item-current' );
			item.removeAttribute( 'data-carousel-item-next' );
		} );

		previous.setAttribute( 'data-carousel-item-previous', '' );
		current.setAttribute( 'data-carousel-item-current', '' );
		next.setAttribute( 'data-carousel-item-next', '' );

		// Update is-dark attribute
		if ( current.hasAttribute( 'data-carousel-item-is-dark' ) ) {
			this.setAttribute( 'data-carousel-is-dark', '' );
		} else {
			this.removeAttribute( 'data-carousel-is-dark' );
		}

		// update item navigation
		this.setAttribute( 'data-carousel-index', this.currentIndex + 1 );
	}

	isPlaying() {
		return this.hasAttribute( 'data-carousel-playing' );
	}

	play() {
		return new Promise( ( resolve, reject ) => {
			if ( this.isPlaying() ) {
				reject( new Error( 'Carousel: Already playing' ) );

				return;
			}

			// set playing to true
			this.setAttribute( 'data-carousel-playing', '' );
			this.timeout = setTimeout( this._timeoutElapsed, this.delay );


			const itemsContainer = this.querySelector( '[data-carousel-items]' );
			if ( itemsContainer ) {
				itemsContainer.setAttribute( 'aria-live', 'off' );
			}
			resolve();

			return;
		} );
	}

	pause() {
		return new Promise( ( resolve, reject ) => {
			if ( !this.isPlaying() ) {
				reject( new Error( 'Carousel: Already paused' ) );

				return;
			}

			// set playing to false
			this.removeAttribute( 'data-carousel-playing' );
			clearTimeout( this.timeout );

			const itemsContainer = this.querySelector( '[data-carousel-items]' );
			if ( itemsContainer ) {
				itemsContainer.setAttribute( 'aria-live', 'polite' );
			}
			resolve();

			return;
		} );
	}

	setCurrentIndex( val ) {
		if ( isNaN( val ) ) {
			return;
		}

		if ( 0 > val || val >= this.getItems().length ) {
			return;
		}

		this.currentIndex = val;
	}

	getItems() {
		return this.querySelectorAll( '[data-carousel-item]' );
	}

	getPreviousIndex() {
		// check if items not available
		if ( 2 > this.getItems().length ) {
			return this.currentIndex;
		}

		if ( 0 < this.currentIndex ) {
			return ( this.currentIndex - 1 );
		}

		return this.getItems().length - 1;
	}

	getNextIndex() {
		// check if items not available
		if ( 2 > this.getItems().length ) {
			return this.currentIndex;
		}

		if ( this.currentIndex < ( this.getItems().length - 1 ) ) {
			return ( this.currentIndex + 1 );
		}

		return 0;
	}

	_addEventListeners() {
		this.addEventListener( 'click', this._clickHandler );
		this.addEventListener( 'focusin', this._focusHandler );
		this.addEventListener( 'mouseenter', this._mouseEnterHandler );
		this.addEventListener( 'mouseleave', this._mouseLeaveHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'click', this._clickHandler );
		this.removeEventListener( 'focusin', this._focusHandler );
		this.removeEventListener( 'mouseenter', this._mouseEnterHandler );
		this.removeEventListener( 'mouseleave', this._mouseLeaveHandler );
	}
}

customElements.define( 'mr-carousel', MrCarousel );

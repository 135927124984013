import { renderEstablishmentSearchResults } from '../../helpers/establishments-search-results.js';

class MrEstablishmentsFinder extends HTMLElement {
	// https://sereni.wp.test/wp-json/establishments/all.json
	// https://loc.geopunt.be/v4/Location?q=2060&c=10
	constructor() {
		/** If you define a constructor, always call super() first!
		 * This is specific to CE and required by the spec.
		 */
		super();

		this._isBusy = false;

		this._submitHandler = ( e ) => {
			e.preventDefault();
			e.stopPropagation();

			if ( !e.target || 'FORM' !== e.target.tagName ) {
				return; // Poorly formed template, nothing to do here
			}

			const data = new FormData( e.target );
			this.render( data.get( 'query' ) );
		};

		// remove focus style after mouseup
		// this does not effect keyboard navigation
		this._blurHandler = ( e ) => {
			if ( 'BUTTON' === e.target.tagName && 'submit' === e.target.type ) {
				e.target.blur();
			}
		};
	}

	async render( query ) {
		if ( this._isBusy ) {
			return;
		}

		if ( !query ) {
			return;
		}

		this._isBusy = true;
		this.removeNotFoundState();
		this.removeErrorState();
		this.setLoadingState();

		const liveArea = document.getElementById( this.getAttribute( 'live-region' ) );
		const renderArea = document.getElementById( this.getAttribute( 'render-area' ) );

		try {
			const hasResults = await renderEstablishmentSearchResults( {
				query: query,
				liveArea: liveArea,
				liveAreaLoadedMsg: {
					singular: this.getAttribute( 'live-region-singular-loaded-message' ),
					plural: this.getAttribute( 'live-region-plural-loaded-message' ),
				},
				liveAreaLoadingMsg: this.getAttribute( 'live-region-loading-message' ),
				renderArea: renderArea,
				renderVariant: this.getAttribute( 'render-variant' ),
			} );

			if ( !hasResults ) {
				this.setNotFoundState();
			}

		} catch ( err ) {
			// Error handling is hard here
			// Aside from dev errors (you're holding it wrong) only network and api errors can happen
			// Beter to have a good test for our API implementation
			console.warn( err );
			this.setErrorState();
		} finally {
			this._isBusy = false;
			this.removeLoadingState();
		}
	}

	setLoadingState() {
		this.setAttribute( 'loading', 'loading' );
	}

	removeLoadingState() {
		this.removeAttribute( 'loading' );
	}

	setErrorState() {
		this.removeNotFoundState();
		this.setAttribute( 'error', 'error' );
	}

	removeErrorState() {
		this.removeAttribute( 'error' );
	}

	setNotFoundState() {
		this.removeErrorState();
		this.setAttribute( 'not-found', 'not-found' );
	}

	removeNotFoundState() {
		this.removeAttribute( 'not-found' );
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();

		// Nested inputs have a disabled attribute to prevent submits before JS is loaded and ready
		// Remove disabled state from inputs when connected
		window.requestAnimationFrame( () => {
			this.querySelectorAll( '[disabled]' ).forEach( ( disabledEl ) => {
				disabledEl.removeAttribute( 'disabled' );
			} );
		} );

		// Remove disabled state from inputs (just making sure here)
		setTimeout( () => {
			this.querySelectorAll( '[disabled]' ).forEach( ( disabledEl ) => {
				disabledEl.removeAttribute( 'disabled' );
			} );
		}, 500 );
	}

	disconnectedCallback() {
		this._removeEventListeners();

		try {
			const renderArea = document.getElementById( this.getAttribute( 'render-area' ) );
			renderArea.innerHTML = '';

			this.removeAttribute( 'loading' );
			this.removeAttribute( 'error' );

		} catch ( err ) {
			// no fallback possible here
			console.warn( err );
		}
	}

	_addEventListeners() {
		this.addEventListener( 'submit', this._submitHandler );
		this.addEventListener( 'mouseup', this._blurHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'submit', this._submitHandler );
		this.addEventListener( 'mouseup', this._blurHandler );
	}
}

customElements.define( 'mr-establishments-finder', MrEstablishmentsFinder );
